.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  background-color: white;
}

.header {
  padding-left: 8rem;
  padding-right: 8rem;
  background-image: url("./assets/header_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.menu-item-box {
  display: flex;
  align-items: center;
}

.menu-item {
  text-decoration: none;
  margin: 1.5rem;
  color: white;
  cursor: pointer;
}

.menu-item-button {
  text-decoration: none;
  margin: 1.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: black;
  font-weight: 600;
  background-color: #A6F100;
  border-radius: 8px;
}

.header-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 6rem;
}

.header-content-box {
  width: 50%;
}

.header-content-title {
  font-size: 35px;
  font-weight: 700;
  /* line-height: 46px; */
  color: white;
}

.header-content-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: white;
  margin-top: 30px;
}

.header-content-image {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 50%;
}

.works-container {
  background-image: url("./assets/works_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  margin-top: -10rem;
  padding-top: 6rem;
  padding-bottom: 12rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.works-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5rem;
  width: auto;
  margin-right: -8rem;
}

.works-logo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3rem;
}

.developed-company-icon {
  margin-left: 2rem;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5rem;
}

.works-content {
  width: 50%;
}

.works-content-title {
  font-size: 36px;
  font-weight: 600;
  color: white;
}

.works-content-text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  color: white;
  word-break: break-word;
}

.trade-image {
  width: 45%;
  height: fit-content;
}

.browse-image {
  width: 60%;
}

.offer-image {
  width: 30%;
}

.exchange-image {
  width: 100%;
}

.get-app-container {
  padding-top: 6rem;
  padding-bottom: 12rem;
  padding-left: 8rem;
  padding-right: 8rem;
  display: flex;
  flex-direction: column;
}

.get-app-content {
  padding-left: 6rem;
  padding-right: 6rem;
  text-align: center;
}

.get-app-content-title {
  font-size: 35px;
  font-weight: 600;
}

.get-app-content-text {
  margin-top: 20px;
  font-size: 20px;
}

.button-box {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 6rem;
}

.button-android {
  width: fit-content;
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #7FAC4A;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
}

.button-ios {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 10px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  margin: 10px;
  cursor: pointer;
}

.button-email {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 10px;
  background-color: #7FAC4A;
  margin: 10px;
  cursor: pointer;
}

.button-text {
  font-size: 16px;
  font-weight: 600;
  margin-left: 12px;
  margin-top: 4px;
}

.roadmap-container {
  background-image: url("./assets/works_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  margin-top: -10rem;
  padding-top: 6rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.roadmap-title {
  margin-top: 3rem;
  font-size: 35px;
  font-weight: 600;
  color: white;
}

.roadmap-content {
  background-size: contain;
  margin-top: 3rem;
  width: 100%;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 10rem;
}

.green-bar {
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  height: 100%;
  /* min-width: 100%; */
}


.roadmap-left {
  width: 40%; 
}

.roadmap-center {
  width: 20%;
  z-index: 1;
  margin-top: -20px;
}

.roadmap-right {
  width: 40%; 
}

.roadmap-label {
  font-size: 60px;
  font-weight: 600;
  color: white;
  text-align: center;
}

.roadmap-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #7FAC4A;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.roadmap-item-image {
  width: 70%;
  height: auto;
}

.roadmap-item-title {
  font-size: 40px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-top: 1rem;
}

.roadmap-item-text {
  align-self: flex-start;
  color: white;
}

.roadmap-bottom-split {
  position: absolute;
  background-image: url("./assets/roadmap-bottom-split.png");
  height: 150px;
  width: 100%;
  margin-top: -5rem;
}

.workflow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #211B16;
  padding-top: 6rem;
  padding-bottom: 12rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.workflow-title {
  padding-top: 6rem;
  font-size: 40px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-top: 1rem;
}

.workflow-content {
  background-size: contain;
  margin-top: 3rem;
  width: 100%;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 10rem;
}

.workflow-left {
  width: 40%; 
}

.workflow-center {
  width: 20%;
  z-index: 1;
  margin-top: -20px;
}

.workflow-right {
  width: 40%; 
}

.workflow-item {
  background-color: white;
  color: #000;
  font-size: 25px;
  font-weight: 600;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
}

.workflow-item-1 {
  background-color: #7FAC4A;
  color: white;
  font-size: 25px;
  font-weight: 600;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
}

.list-type-icon {
  position: absolute;
  margin-top: -6rem;
  margin-right: -5rem;
  top: 0;
  right: 0;
}

.people-icon-1 {
  position: absolute;
  margin-top: -3em;
  margin-right: 1rem;
  top: 0;
  right: 0;
}

.people-icon-2 {
  position: absolute;
  margin-top: 2rem;
  margin-right: 5rem;
  top: 0;
  right: 0;
}

.bubble-mailer-icon {
  position: absolute;
  margin-top: -6rem;
  margin-left: -4rem;
  top: 0;
  left: 0;
}

.download-app-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  margin-top: 2rem;
  width: 100%;
  background: linear-gradient(180deg, #4E4137 0%, #7E6A5A 100%);
}

.download-app-content {
  width: 50%;
}

.download-app-title {
  font-size: 35px;
  font-weight: 600;
  color: white;
  margin-top: 1rem;
}

.download-app-text {
  font-size: 20px;
  color: white;
}

.download-button-group {
  display: flex;
  flex-wrap: wrap;
}

.footer-top-split {
  background-image: url("./assets/footer-top-split.png");
  height: 100px;
  width: 100%;
  margin-top: -5rem;
}

.footer-container {
  background-color: #5F6B39;
  padding-top: 1rem;
  padding-bottom: 3rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-content {
  display: flex;
  margin-top: 1.5rem;
}

.footer-item {
  min-width: 20%;
  display: block;
}

.footer-item-title {
  padding: 0.5rem;
  font-size: 25px;
  font-weight: 600;
  color: white;
}

.footer-item-link {
  display: block;
  padding: 0.5rem;
  font-size: 16px;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.footer-email-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}

.footer {
  background-color: #596436;
  padding: 1rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.footer-text {
  color: #90A05A;
}


@media only screen and (max-width: 900px) {
  .header {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .header-content {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }

  .header-content-box {
    width: 100%;
  }

  .header-content-title {
    font-size: 25px;
  }

  .header-content-text {
    font-size: 16px;
  }

  .header-content-image {
    margin-top: -3rem;
    width: 80%;
  }

  .row {
    margin-top: 3rem;
    flex-wrap: wrap;
  }

  .works-container {
    padding-top: 6rem;
    padding-bottom: 10rem;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: hidden;
  }

  .works-box {
    flex-wrap: wrap-reverse;
    margin-right: -3rem;
    margin-top: 2rem;
  }

  .works-logo {
    width: 100%;
    justify-content: center;
  }

  .developed-company-icon {
    margin-left: 0;
  }

  .works-content {
    width: calc(100% - 3rem);
  }

  .works-content-title {
    font-size: 25px;
  }
  
  .works-content-text {
    margin-top: 16px;
  }

  .trade-image {
    width: 100%;
    margin-bottom: 3rem;
  }

  .browse-image {
    margin-top: 3rem;
    width: 100%;
  }

  .offer-image {
    margin-top: 3rem;
    width: 100%;
  }

  .exchange-image {
    margin-top: 2rem;
    width: 100%;
  }

  .get-app-container {
    padding-top: 6rem;
    padding-bottom: 16rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .get-app-content {
    padding: 0rem;
  }

  .get-app-content-title {
    font-size: 25px;
  }

  .get-app-content-text {
    font-size: 16px;
  }

  .button-box {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .note-image {
    width: 100%;
    margin-bottom: 2rem;
  }

  .get-app-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .roadmap-container {
    padding-top: 6rem;
    padding-bottom: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .roadmap-item-title {
    font-size: 25px;
  }

  .roadmap-item-text {
    font-size: 16px;
  }

  .roadmap-title {
    font-size: 25px;
  }

  .roadmap-left {
    display: none;
  }

  .roadmap-right {
    width: 70%;
  }

  .roadmap-content {
    margin-top: 1rem;
    padding-bottom: 0;
  }

  .green-bar {
    left: 10%;
  }

  .workflow-container {
    padding-top: 4rem;
    padding-bottom: 12rem;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: hidden;
  }

  .workflow-content {
    padding-bottom: 2rem;
  }

  .workflow-title {
    font-size: 25px;
  }

  .workflow-item {
    font-size: 16px;
    width: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.7rem;
  }

  .workflow-item-1 {
    font-size: 16px;
    width: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.7rem;
  }

  .workflow-left {
    display: none;
  }

  .workflow-right {
    width: 70%;
  }

  .list-type-icon {
    width: 70%;
    margin-top: -6rem;
    margin-right: -1rem;
  }

  .people-icon-1 {
    width: 30%;
    margin-top: -4rem;
    margin-right: -1rem;
  }

  .people-icon-2 {
    width: 30%;
    margin-top: 0.5rem;
    margin-right: 5rem;
  }

  .bubble-mailer-icon {
    width: 50%;
    margin-top: -4rem;
    margin-left: 10rem;
  }

  .download-app-title {
    font-size: 25px;
  }

  .download-app-text {
    font-size: 16px;
  }

  .download-app-box {
    width: auto;
  }

  .download-app-content {
    width: 100%;
  }

  .download-button-group {
    margin-top: 1rem;
  }

  .footer-container {
    padding-top: 0rem;
    padding-bottom: 6rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .footer-logo {
    width: 100%;
    flex-direction: column;
  }

  .footer-logo svg {
    width: 100%;
  }

  .footer-content {
    flex-direction: column;
    margin-top: 3rem;
  }

  .footer-item-title {
    padding: 0;
    font-size: 25px;
  }

  .footer-item-link {
    font-size: 16px;
    padding: 0.2rem;
  }

  .button-email {
    margin: 0;
  }

  .footer-email-button {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .footer {
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .mobile-menu {
    background-color: #5F6B39;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  
  .mobile-menu-item-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
  }

  .mobile-menu-item {
    text-decoration: none;
    margin: 1rem;
    color: white;
    cursor: pointer;
  }

  .mobile-menu-item-button {
    text-decoration: none;
    margin: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: black;
    font-weight: 600;
    background-color: #A6F100;
    border-radius: 8px;
  }

  .menu-opened-container {
    height: 100vh;
    overflow: hidden;
  }

  .menu-opened-body {
    display: none;
  }

  .mobile-menu-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #596436;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
  }
}